.showCheckoutContent {
  float: none;
  width: 420px;
  position: fixed;
  height: 100%;
  z-index: 98;
  overflow-y: scroll;
  background: white;
  right: 0;
  top: 0;
  padding: 0px 25px 23px;
  border: 0.5px solid rgba(158, 171, 168, 1);
}
.showCheckoutContent::-webkit-scrollbar {
  display: none;
}
.locationcloseIcon {
  font-size: 12px;
  font-weight: 500;
}
.navbar-thankyou {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  margin-bottom: 15px;
  border: none;
}
.right-side {
  display: flex;
  align-items: center;
  color: rgba(73, 87, 87, 1);
  gap: 6px;
  cursor: pointer;
}
.thankYouPage {
  position: relative;
}
.checkIcons img {
  width: 40px;
  height: 40px;
}
.checkIcons {
  text-align: center;
  margin: 10px auto 14px;
}
.thankYouMsg {
  color: #495757;
  text-align: center;
  margin: 0 0 10px;

  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
}
.thankSubtext {
  text-align: center;
  color: #8c8c8c;
  margin: 0 0 28px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.thankyouEmail {
  font-weight: 600;
}
.orderTotoalThx .devider {
  width: 100%;
  border: none;
  height: 0.5px;
  background: #bae7dc;
}
.devider-store {
  width: 100%;
  border: none;
  height: 0.5px;
  background: #bae7dc;
}
.orderTotoalThx {
  height: 20px;
  text-align: center;
  position: relative;
}
.orderTotText {
  color: #6b7280;
  position: absolute;
  left: 0;
  right: 0;
  top: -9px;
  width: 85px;
  margin: auto;
  background: white;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.orderTotalNumber {
  color: #495757;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}
.orderSeeAllDetails i {
  margin-left: 6px;
}
.orderSeeAllDetails {
  font-size: 14px;
  color: black;
  line-height: 19px;
  text-align: center;
  margin: 0 0 12px;
  font-weight: 400;
}
.pickup-message-container {
  display: flex;
  justify-content: center;
}
.pickup-message {
  color: #ff9900;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  width: 269px;
  margin: 5px 0px 10px 0px;
}
.payNowBigBtn {
  background: rgba(0, 96, 204, 1);
  color: #fff;
  width: 100%;
  max-width: 371px;
  opacity: 1;
  cursor: pointer;
  height: 52px;
  padding: 16px;
  border-radius: 5px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.orderTotText-store {
  color: #6b7280;
  position: absolute;
  left: 0;
  right: 0;
  top: -9px;
  margin: auto;
  background: white;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  width: fit-content;
  padding: 0px 10px;
}
.orderTotoalThx-store {
  height: 20px;
  text-align: center;
  position: relative;
  margin-top: 25px;
}
.bottom-with-loader {
  position: sticky;
  bottom: 0%;
  z-index: 100;
}
@media screen and (max-width: 405px) {
  .showCheckoutContent {
    float: none;
    width: 100%;
    position: fixed;
    height: calc(100vh);
    z-index: 98;
    overflow-y: scroll;
    background: #121212;
    right: 0;
    top: 0;
    padding: 18px 8px 25px;
    border: 0.5px solid rgba(158, 171, 168, 1);
  }
}
@media screen and (max-width: 425px) {
  .bottom-with-loader {
    position: sticky;
    bottom: 0%;
    z-index: 100;
    background-color: white;
    padding-bottom: 35px;
  }
}
@media screen and (max-width: 345px) {
  .showCheckoutContent {
    float: none;
    width: 100%;
    position: fixed;
    height: calc(100vh);
    z-index: 98;
    overflow-y: scroll;
    background: #121212;
    right: 0;
    top: 0;
    padding: 18px 8px 0px;
    border: 0.5px solid rgba(158, 171, 168, 1);
  }
  .bottom-with-loader {
    position: sticky;
    bottom: 0%;
    z-index: 100;
    background-color: white;
    padding-bottom: 35px;
  }
}
