.prefrence-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.prefrence-text {
  color: #0060cc;
  font-size: 10px;
  margin-left: 5px;
}
.itemPriceFinal {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;

  flex: 1;
  color: #495757;
}
.item-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}
.prodBrandName,
.productMainName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  flex: 3;
}

.prodBrandName {
  color: #8c8c8c;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stock-status {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 6px 0px;
}
.stock-text {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #495757;
}
.open-status {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #495757;
}
.add-to-bag {
  min-width: 82px;
  height: 30px;
  padding: 4px, 8px, 4px, 8px;
  border-radius: 3px;
  background: #f0f4f3;
  border: none;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buy-now {
  min-width: 82px;
  height: 30px;
  padding: 4px, 8px, 4px, 8px;
  border-radius: 3px;
  background: #0060cc;
  border: none;
  color: white;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.open-status-and-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #bae7dc;
  padding-bottom: 5px;
}
.btns {
  display: flex;
  gap: 8px;
}
.store-order {
  /* padding: 10px 0px; */
  margin-top: 14px;
  /* border-top: 0.5px solid #bae7dc;
  border-bottom: 0.5px solid #bae7dc; */
  /* margin-bottom: 20vh; */
}
.distance {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: center;
  flex: 1;
}
.delivery-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px 0px; */
  height: 42px;
  /* margin: 15px 0px; */
}
.border-divider-bottom {
  margin-top: 0px;
  height: 0.5px;
  background-color: #bae7dc;
}
.delivery-price-container {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
  color: #495757;
}
.method-text-container {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #495757;
}
@media screen and (max-width: 425px) {
  .delivery-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0px 0px; */
    height: 42px;
    /* margin: 15px 0px; */
    /* margin-bottom: 80px; */
  }
  .store-order {
    /* padding: 10px 0px; */
    margin-top: 14px;
    /* border-top: 0.5px solid #bae7dc;
    border-bottom: 0.5px solid #bae7dc; */
    margin-bottom: 70px;
  }
}
