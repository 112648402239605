.loading-screen {
  /* position: absolute; */
  /* top: 0; */
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
}

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #0060cc;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
