.qucikText {
  background: rgba(0, 96, 204, 0.1);
  display: inline-block;
  padding: 4px 8px;
  color: #0060cc;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  border-radius: 500px;
}
.moreInfoClose.makeItRedbg {
  width: 47px;
  height: 30px;
  border-radius: 5px;
  padding: 4px, 8px, 4px, 8px;
  background: rgba(240, 244, 243, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #293a3a;
  cursor: pointer;
  font-weight: 400;
}
.showCheckoutContent {
  float: none;
  width: 420px;
  position: fixed;
  height: calc(100vh);
  z-index: 98;
  overflow-y: scroll;
  background: #121212;
  right: 0;
  top: 0;
  padding: 18px 25px 24px;
  border: 0.5px solid rgba(158, 171, 168, 1);
}
.relatedTextHead {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.navbar-quick-chck {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 19px 0px;
  border-bottom: 0.5px solid #bae7dc;
  margin-bottom: 18px;
  width: 100%;
}
.right-side {
  display: flex;
  align-items: center;
  color: rgba(73, 87, 87, 1);
  gap: 6px;
}
.locationcloseIcon {
  font-size: 12px;
  font-weight: 500;
}
.close {
  display: flex;

  align-items: center;
  cursor: pointer;
}
.left-side {
  display: flex;
  align-items: center;
  gap: 10px;
}
.loading-text {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #495757;
  margin-top: 15px;
}

.bottom-with-loader-c {
  position: sticky;
  bottom: 0%;
  z-index: 100;

  padding-bottom: 23px;
}
@media screen and (max-width: 425px) {
  .bottom-with-loader-c {
    position: sticky;
    bottom: 0%;
    z-index: 100;
    background-color: white;
    padding-bottom: 60px;
  }
}
@media screen and (max-width: 345px) {
  .bottom-with-loader-c {
    position: sticky;
    bottom: 0%;
    z-index: 100;
    background-color: white;
    padding-bottom: 35px;
  }
}
