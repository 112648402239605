.paymentCards {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  max-width: 372px;
  width: 100%;
  height: 40px;
  justify-content: center;
}
.paymentMethod {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 10px;
  width: 118.67px;
  height: 40px;
  border-radius: 6px;
}
.paywithCards {
  margin-bottom: 25vh;
}
.paymentMethod img {
  width: 113.67px;
  height: 40px;
}
.divider {
  height: 0.5px;
  width: 100%;
  background-color: rgba(186, 231, 220, 1);
  margin: 8px 0px 8px 0px;
  position: relative;
}
.paymentComponentDiv .subHeadingEighteen {
  position: relative;
}

.paymentComponentDiv .subHeadingEighteen {
  margin-bottom: 16px;
  display: flex;
}
.subHeadingEighteen {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
.paymentComponentDiv {
  float: left;
  width: 100%;
  display: block;
  margin-top: 2px;
  padding: 1px;
}
.dashlineTextinside {
  float: left;
  width: 100%;
  position: relative;
  margin: 8px 0 0;
}
.fullDiv.tripleElement {
  margin: 0 -8px;
  display: flex;
}

.fullDiv.tripleElement .form-group {
  position: relative;
  width: 33.3%;
  display: inline-block;
  padding: 0 8px;
}
.fullDiv.tripleElement .form-group:last-child {
  text-align: right;
  float: right;
  background: transparent;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fullDiv.tripleElement .form-group {
  width: 33.3%;
  display: inline-block;
  padding: 0 8px;
}
.paymentComponentDiv .subHeadingEighteen::after {
  background: #bae7dc;
  width: 100%;
  position: absolute;
  top: 13px;
  content: "";
  left: 0;
  height: 0.5px;
}

.dashedText {
  font-size: 10px;
  line-height: 14px;
  color: rgba(107, 114, 128, 1);
  text-align: center;
  position: absolute;
  top: 16px;
  margin: auto;
  left: 0;
  right: 0;
}
.orContinueText {
  background-color: white;
  padding: 0px 5px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.StripeElement--focus {
  border-color: #0060cc;
  border-width: 0.5px;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #0060cc;
}
.StripeElement--invalid {
  border-color: red;

  outline: 0;
  box-shadow: 0 0 0 0.5px red;
}
.dashedText-cards {
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  text-align: center;
  top: 6px;
  margin: auto;
  left: 0;
  right: 0;
  color: rgba(107, 114, 128, 1);
  position: absolute;
}
@media screen and (max-width: 405px) {
  .showCheckoutContent {
    float: none;
    width: 320px;
    position: fixed;
    height: calc(100vh);
    z-index: 98;
    overflow-y: scroll;
    background: #121212;
    right: 0;
    top: 0;
    padding: 18px 25px 24px;
    border: 0.5px solid rgba(158, 171, 168, 1);
  }
  .paymentMethod {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;
    width: 84.67px;
    height: 40px;
    border-radius: 6px;
    justify-content: center;
  }
  .paymentMethod img {
    width: 80.67px;
    height: 40px;
  }
}
@media screen and (max-width: 425px) {
  .paywithCards {
    margin-bottom: 0vh !important;
  }
}
