.singleCheckoutheadiDiv {
  margin: 0 0 18px;
  line-height: 21.79px;
  font-size: 16px;
  font-weight: 600;
}
.bagNumberCount {
  height: 30px;
  border-radius: 500px;
  background-color: rgba(140, 140, 140, 0.1);
  padding: 4px 8px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  width: 100px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #293a3a;
}

.right-nav-bag {
  display: flex;
  gap: 10px;
}
.sort-btn {
  height: 30px;
  border-radius: 5px;
  padding: 4px 8px 4px 8px;
  background: #f0f4f3;
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 50px;
  cursor: pointer;
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.moreInfoClose.makeItRedbg {
  width: 47px;
  height: 30px;
  border-radius: 5px;
  padding: 4px, 8px, 4px, 8px;
  background: #f0f4f3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}
.checkNew.bagisOpen {
  height: 100%;
  position: fixed;
  width: 420px;
  right: 0;
  z-index: 99999;
  top: 0px;
  background: #ffffff;
  padding: 18px 24px 0px;
  overflow-y: scroll;
}
.bag-data-continer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
}
.bag-nav {
  margin-bottom: 17px;
}
.delivery-method-contain {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 32px;
  color: #293a3a;
  background-color: #f0f4f3;

  border-radius: 5px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
}
.singlePrBtnsShareBag {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  max-width: 409px;
  height: 48px;
  justify-content: center;
  /* margin-top: 6px; */
}
.shareBagifn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  /* width: 122px;
  height: 48px; */
  background: #212121;
  box-shadow: 0px 0px 4px rgba(30, 38, 49, 0.35);
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 24px; */
  cursor: pointer;
  width: 153px;
  height: 48px;
  border-radius: 5px;
  gap: 10px;

  line-height: 20px;
}
.prodeedCheckout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 153px;
  height: 48px;
  background: #0060cc;
  box-shadow: 0px 0px 4px rgba(30, 38, 49, 0.35);
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  border-radius: 5px;
}
.optimize-terms-cond {
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #6b7280;
  margin-top: 16px;
}
@media screen and (max-width: 420px) {
  .checkNew.bagisOpen {
    width: 100%;
  }
}
