.powered-by {
  position: sticky;
  bottom: 0% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  z-index: 3;
  background-color: white;
  overflow: hidden;
  min-height: 50px !important;
}
@media screen and (max-width: 425px) {
  .powered-by {
    /* bottom: 0px; */
    padding-bottom: 16px;
    bottom: 5% !important;
    min-height: 90px !important;

    padding: 10px 0px;
  }
}
@media screen and (max-width: 345px) {
  .powered-by {
    /* bottom: 0px; */
    padding-bottom: 40px;
  }
}
