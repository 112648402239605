.mapandBlack {
  border-radius: 10px;
  -webkit-overflow: hidden;
  -ms-overflow: hidden;
  overflow: hidden;
  height: 181.72px !important;

  position: relative;
  margin-top: 10px;
}
.mapandBlack {
  height: 181px;
}
.mapandBlack .gm-style {
  border-radius: 10px;
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  padding: 0px;
  border-width: 0px;
  margin: 0px;
}
.mapandBlack .gm-control-active.gm-fullscreen-control::after {
  content: "\f31d";
  font-family: "Font Awesome 6 Pro";
  color: #fff;
  font-size: 18px;
  font-weight: 100;
}
.mapandBlack .gm-control-active.gm-fullscreen-control {
  background-color: #212121 !important;
  border-radius: 5px !important;
}
.mapandBlack > div {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-overflow: hidden;
  overflow: hidden;
  -webkit-overflow: hidden;
}
.mapandBlack > div > div {
  background-color: #17263c !important;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
.mapandBlack .gm-style {
  border-radius: 5px;
}
