.customerDetailsIn {
  display: block;
  float: left;
  width: 100%;
  margin: 12px 0 0;
  padding: 1px;
}
.customerDetailsIn .subHeadingEighteen {
  position: relative;
  margin-bottom: 16px;
  display: flex;
}
.subHeadingEighteen {
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}
.headingLines {
  padding-right: 16px;
  background: white;
  position: relative;
  z-index: 1;
  color: #495757;
  font-weight: 400;
}
.customerDetailsIn .subHeadingEighteen::after {
  background: #bae7dc;
  width: 100%;
  position: absolute;
  top: 15px;
  content: "";
  left: 0;
  height: 0.5px;
}
.customrow .form-group {
  margin-bottom: 16px;
}
.halfDiv {
  display: flex;

  justify-content: space-between;
}
.halfDiv .form-group {
  width: 48%;
  display: inline-block;
}
.customrow .form-control {
  height: 50px;
  border: 0.5px solid rgba(158, 171, 168, 1);
  border-radius: 5px;
  padding: 15px;
  font-size: 14px;
  color: rgba(41, 58, 58, 1);
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.PhoneInput {
  display: flex;
  align-items: center;
}

.PhoneInput {
  width: 107px;
  position: absolute;
  height: 50px;
  padding-left: 16px;
}
.PhoneInput:focus {
  width: 107px;
  position: absolute;
  height: 50px;
  padding-left: 16px;
  color: #121212;
}
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountryIcon {
  height: 22px !important;
  width: 22px !important;
  border-radius: 50%;
  background: #3f3f3f;
}
.PhoneInputCountryIcon--border {
  background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor),
    inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
      var(--PhoneInputCountryFlag-borderColor);
}
.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
  height: 22px;
  width: 22px;
  border-radius: 50%;
}
.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}

.PhoneInputCountryIconImg {
  object-fit: cover;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 0;
}
.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelectArrow {
  color: #fff;
  opacity: 1;
  font-size: 28px;
  position: relative;
  top: -2px;
}
span.numberValues {
  position: absolute;
  color: #fff;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  text-align: left;
}
span.numberValues::after {
  content: "";
  background: #3f3f3f;
  height: 20px;
  width: 2px;
  position: relative;
  right: -9px;
}

:after,
:before {
  box-sizing: border-box;
}
.fullDivCreateAccount {
  color: #fff;
  font-size: 10px;
  line-height: 13.62px;
  font-weight: 600;
  text-align: right;
  cursor: pointer;
  background: #212121;
  float: right;
  padding: 4px 8px;
  border-radius: 500px;
  min-width: 90px;
  min-height: 22px;
}
.customrow .form-control.mobileEnter {
  padding-left: 126px;
}
.form-group.phoneNumberSlot {
  position: relative;
}
.PhoneInput {
  width: 107px;
  position: absolute;
  height: 50px;
  padding-left: 16px;
}
.customrow .form-control.mobileEnter {
  padding-left: 126px;
  color: rgba(41, 58, 58, 1);
}
.PhoneInputInput {
  display: none;
}
.PhoneInputCountrySelectArrow {
  opacity: 1;
  /* font-size: 28px; */
  position: relative;
  /* top: -2px; */
  width: 8px;
  rotate: 45deg;
  height: 8px;
  margin-left: 10px;

  color: #121212;
}
.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
  height: 22px;
  width: 22px;
  border-radius: 50%;
}
.PhoneInputCountryIconImg {
  object-fit: cover;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  padding: 0;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none !important;
}
.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  + .PhoneInputCountrySelectArrow {
  box-shadow: none !important;
  color: black !important;
}
.PhoneInputCountrySelect:focus
  + .PhoneInputCountryIcon
  .PhoneInputInternationalIconGlobe {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.PhoneInputCountryIcon {
  height: 22px !important;
  width: 22px !important;
  border-radius: 50%;
  background: #3f3f3f;
}
.PhoneInputCountryIcon svg {
  color: rgba(166, 166, 166, 0.2);
}
.phoContain {
  width: 107px;
  position: absolute;
}
span.numberValues {
  position: absolute;
  color: #fff;
  right: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  text-align: left;
  font-size: 14px;
}
span.numberValues::after {
  content: "";
  background: #3f3f3f;
  height: 20px;
  width: 2px;
  position: relative;
  right: -9px;
}
