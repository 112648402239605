.showCheckoutContent-error {
  float: none;
  width: 420px;
  position: fixed;
  height: calc(100vh);
  z-index: 11111111;
  overflow-y: scroll;
  background: white;
  right: 0;
  top: 0;
  padding: 18px 25px 24px;
  border: 0.5px solid rgba(158, 171, 168, 1);
}
@media screen and (max-width: 405px) {
  .showCheckoutContent-error {
    float: none;
    width: 315px;
    position: fixed;
    height: calc(100vh);
    z-index: 98;
    overflow-y: scroll;
    background: white;
    right: 0;
    top: 0;
    padding: 18px 8px 24px;
    border: 0.5px solid rgba(158, 171, 168, 1);
  }
}
