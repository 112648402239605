.showCheckoutContent {
  float: none;
  width: 420px;
  position: fixed;
  height: calc(100vh);
  z-index: 98;
  overflow-y: scroll;
  background: #121212;
  right: 0;
  top: 0;
  padding: 0px 25px 24px;
  border: 0.5px solid rgba(158, 171, 168, 1);
}
.address {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: baseline;
}
.heading {
  display: flex;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #6b7280;
}
.user-address {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #495757;
}
.delivery-slider {
  background-color: #f0f4f3;
  height: 38px;
  min-width: 204px;
  border-radius: 5px;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 17px;
  padding: 5px 5px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  color: #212121;
}
.delivery-options.active {
  background-color: white;
  border-radius: 5px;
  color: #495757;
}
.delivery-options {
  letter-spacing: 0;
  min-height: 28px;
  width: 100%;
  margin-bottom: 0;
  cursor: pointer;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #6d7e7e;
  display: flex;
  justify-content: center;
  align-items: center;
}
.change-address {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.border-divider {
  /* padding: 10px 0px; */
  margin-top: 17px;
  /* border-top: 0.5px solid #bae7dc;
  border-bottom: 0.5px solid #bae7dc; */
  height: 0.5px;
  background-color: #bae7dc;
}
.loader-mini-container {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 6px;
}
.search-loader-text {
  font-family: Open Sans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #6b7280;
}
