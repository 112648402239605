.thisMyLocation {
  max-width: 370px;
  height: 216px;
  background: #212121;
  border-radius: 10px;
  margin: 20px 0 20px;
  padding: 16px 16px 20px;
  position: relative;
  width: 100%;
}
.thankYouPage .thisMyLocation {
  margin-bottom: 16px;
}
.storeImgSource {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  height: 100%;
  border-radius: 5px;
}
.bgOverlay {
  /* background-image: linear-gradient(
    84.4deg,
    #212121 43.8%,
    rgba(24, 24, 24, 0) 62.78%
  ); */
  background: linear-gradient(
    84.4deg,
    #f0f4f3 43.8%,
    rgba(255, 255, 255, 0) 62.78%
  );

  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 5px;
}
.thankYouPage .thisMyLocation.sToreLocate .openFrameNow {
  height: auto;
  min-height: 189px;
  gap: 7px;
}
.openFrameNow {
  max-width: 340px;
  height: 142px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  z-index: 2;
  position: relative;
  width: 100%;
}
.openNowBox.cloeNowBox {
  color: red;
  align-items: center;
  justify-content: center;
  background: rgb(247 0 0 / 10%);
}
.openNowBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  width: 66px;
  height: 22px;
  background: rgba(105, 194, 63, 0.1);
  border-radius: 500px;
  color: #69c23f;
  font-size: 10px;
}
.storeBigName {
  color: #495757;
  max-height: 33px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  width: 127px;
  height: 33px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.storeBigLocation {
  line-height: 16px;
  color: #8c8c8c;
  width: 127px;
  min-height: 32px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 100%;
  overflow: hidden;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.storeTimeing {
  color: #495757;
  height: 32px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.storeMoreInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #495757;
  min-width: 87px;
  background: #ffffff;

  font-size: 12px;
  line-height: 32px;
  cursor: pointer;

  height: 36px;
  padding: 16px;
  border-radius: 5px;
  gap: 10px;
}
.storeMoreInfo.map {
  margin-left: 10px;
  /* min-width: 89px; */
  padding: 0px 10px;
  width: 117.2px;

  gap: 10px;
}
