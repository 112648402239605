.orderProductDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 18px;
  max-width: 372px;
  width: 100%;
  height: 54px;
}
.orderandTracking {
  padding: 12px;
  border: 0.5px solid rgba(186, 231, 220, 1);
  border-radius: 5px;
}
.productImageIcon {
  height: 74px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productDetLine {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  max-width: 180px;
  width: 100%;
  /* height: 67px; */
}
.prodBrandName {
  color: #8c8c8c;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.productMainName {
  font-size: 16px;
  font-weight: 600;
  color: rgba(41, 58, 58, 1);
  line-height: 21px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  top: -1px;
  text-align: left;
}

.checkDetailComponent {
  color: rgba(107, 114, 128, 1);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.productInfoLines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px;
  max-width: 147px;
  width: 100%;
  /* height: 66px; */
  gap: 16.5px;
}

.itemMoneyPr {
  color: #8c8c8c;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
}
.itemPriceFinal-order {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
  color: #6b7280;
}
.sperateBorderclass {
  background: #bae7dc;
  height: 0.5px;
  width: 100%;
  margin: 30px 0;
  max-width: 370px;
  border: none;
}

.active-icon.checkout {
  width: 11px;
  height: 10px;
}
.prefrence-container {
  margin-bottom: 10px;
  display: flex;
}
.prefrence-text {
  color: #0060cc;
  font-size: 10px;
  margin-left: 5px;
}
.courierInfotruck {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;
  max-width: 312px;
  width: 100%;
  height: 60px;
  gap: 18px;
}
.trackCourierInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  max-width: 218px;
  width: 100%;
  height: 64px;
  text-align: left;
}
.courierNames {
  color: rgba(73, 87, 87, 1);
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fromLocations {
  color: rgba(73, 87, 87, 1);
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
}
.pointLocation {
  color: #8c8c8c;
}
.changeCourier.newPrice {
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 0;
  width: 85px;
  height: 68px;
  background: none;
  border-radius: 500px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  cursor: auto;
  justify-content: space-between;
}
.itemMoneyPr {
  color: #8c8c8c;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
}
.change-icon {
  margin-right: 4px;
  width: 9px;
  height: 9px;
  border: 1px;
}
.change-text {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;

  color: #495757;
}
.orderCorierCart {
  display: flex;
  align-items: center;
}
.sperateBorderclassName {
  height: 0.5px;
  width: 100%;
  background-color: rgba(186, 231, 220, 1);
  margin: 12px 0px 12px 0px;
}
.change-icon-text {
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
}
