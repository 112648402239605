::-webkit-scrollbar {
  display: none;
}
.showCheckoutContent {
  float: none;
  width: 420px;
  position: fixed;
  height: calc(100vh);
  z-index: 98;
  overflow-y: scroll;
  background: #121212;
  right: 0;
  top: 0;
  padding: 18px 25px 24px;
  border: 0.5px solid rgba(158, 171, 168, 1);
}
.bothebot-pup.locatonPopDev {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
a.locateBtns {
  background: #f0f4f3;
  height: 44px;
  color: #293a3a;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  max-width: 162px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.location-btn-area.newLook {
  max-width: 336px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: Open Sans;
  gap: 12px;
  width: 100%;
  min-height: 60px;
}
.bothebot-pup.locatonPopDev .location-info-text {
  position: relative;
  bottom: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  max-width: 300px;
  width: 100%;
  min-height: 40px;
}

.location-info-text {
  color: black;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 24px 0 0;
  text-align: center;
}
a.locateBtns.shareUselocarion {
  background: #0060cc;
  color: white;
}
.locationNameDiv {
  color: #495757;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin: 0 0 20px;
}
span.hourglassIcon .form-control {
  border-radius: 5px;
  height: 36px;
  max-width: 371px;
  width: 100%;
  display: block;
  padding: 9px 14px;
  padding-left: 44px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;
  background: rgba(240, 244, 243, 1);
  box-shadow: none;
}
.bothebot-pup.locatonPopDev .location-info-text {
  position: relative;
  bottom: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  max-width: 371px;
  width: 100%;
  min-height: 40px;
}

.location-info-text {
  color: #495757;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin: 24px 0 0;
  text-align: center;
}
span.hourglassIcon img {
  position: absolute;
  left: 14px;
  top: 9px;
  height: 18px;
}
.deliveryDistaneText {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #495757;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.deliveryDistaneText a {
  color: #0060cc;
  display: block;
  text-decoration: none;
  margin: 10px 0;
}
.right-side {
  display: flex;
  align-items: center;
  color: rgba(73, 87, 87, 1);
  gap: 6px;
  cursor: pointer;
}
.left-side img {
  width: 18px;
  height: 18px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 24px 0px;
  border-bottom: 0.5px solid #bae7dc;
  margin-bottom: 15px;
}
.bag-icon {
  margin-right: 12px;
}
.locationnotFound {
  color: red;
  display: block;
}
.terms-conditions {
  margin-top: 10px;
  font-size: 12px;
  color: #495757;
  text-align: center;
}
span.hourglassIcon {
  position: relative;
  display: block;
}
/*End Keep This Product*/
.pac-container {
  background: white !important;
  border: 0.5px solid #bae7dc;
  border-radius: 5px;
  margin-top: 17px;
  padding-left: 16px;
  padding-right: 19px;
  padding-top: 8px;
  z-index: 9999;
  box-shadow: none;
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}
.pac-item-query {
  font-size: 14px;
  padding-right: 3px;
  color: #495757;
}
.pac-matched {
  font-weight: 600;
  color: #495757;
}
.pac-item {
  padding: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #8c8c8c;
  border-top: 0.5px solid #bae7dc;
}
.pac-item:hover {
  background-color: #21212112;
}
.pac-item:first-child {
  border: none;
  padding-top: 0;
}
@media screen and (max-width: 425px) {
  .showCheckoutContent {
    float: none;
    width: 320px;
    position: fixed;
    height: calc(100vh);
    z-index: 98;
    overflow-y: scroll;
    background: #121212;
    right: 0;
    top: 0;
    padding: 18px 25px 24px;
    border: 0.5px solid rgba(158, 171, 168, 1);
  }
}
