.totalToPayCompund {
  float: left;
  width: 100%;
  bottom: 0;
  max-width: 375px;
  background: hsl(0, 0%, 100%);
  position: fixed;
  bottom: 0%;
  z-index: 2;
}
.totalToPay .dashlineTextinside {
  margin: 0;
}
.dashlineTextinside {
  float: left;
  width: 100%;
  position: relative;
  margin: 10px 0;
}
.totalToPay .dashlineTextinside .sperateBorderclass {
  border: none;
  height: 0.5px;
  background: #bae7dc;
}

.dashlineTextinside .sperateBorderclass {
  margin: 16px 0;
}
.sperateBorderclass {
  height: 0.5px;
  width: 100%;
  margin: 25px 0;
  max-width: 370px;
}
.dashedText {
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  text-align: center;
  top: 9px;
  margin: auto;
  left: 0;
  right: 0;
  color: rgba(107, 114, 128, 1);
}
.totalToPay .orContinueText {
  padding: 0 5px;
  background: white;
  font-size: 12px;
  color: rgba(107, 114, 128, 1);
  line-height: 16px;
  font-weight: 400;
}
.maxiMumAmoutn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  max-width: 371px;
  width: 100%;
  height: 96px;
}
.finalTotalAmount {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  width: 100%;
  color: black;
}
.payNowBigBtn {
  background: rgba(0, 96, 204, 1);
  color: #fff;
  height: 48px;
  width: 100%;
  max-width: 371px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 48px;
  text-align: center;
  font-weight: 400;
  opacity: 1;
  cursor: pointer;
}
.termsTextcart {
  color: #8c8c8c;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  margin: 16px 0;
}
@media screen and (max-width: 425px) {
  .totalToPayCompund {
    float: left;
    width: 100%;
    /* bottom: 0; */
    /* max-width: 297px; */
    background: white;
    position: sticky;
    bottom: 0%;
    z-index: 2;
    min-height: 100px;
    padding-bottom: 80px;
    min-width: 100%;
  }
  .payNowBigBtn {
    width: 100% !important;
    /* max-width: 96% !important; */
  }
}
